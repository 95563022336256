import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

const ProductDetails = () => {
  const { name } = useParams();
  const { t, i18n } = useTranslation();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL || "http://api.drkang.kz";

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/cosmeteria-products?filters[name][$eq]=${encodeURIComponent(
            name
          )}&populate=*&locale=${i18n.language}`
        );

        const productData = response?.data?.data?.[0];
        if (productData) {
          const productAttributes = productData.attributes || productData;
          setProduct(productAttributes);
          setCurrentImage(
            productAttributes.mainimage?.formats?.large?.url ||
            productAttributes.mainimage?.url
              ? `${API_URL}${
                  productAttributes.mainimage?.formats?.large?.url ||
                  productAttributes.mainimage?.url
                }`
              : null
          );
        } else {
          setError(t("productNotFound"));
        }
      } catch (err) {
        console.error("Error fetching product:", err);
        setError(t("errorLoadingData"));
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [API_URL, name, t, i18n.language]);

  if (loading) return <div className="text-center text-gray-600">{t("loading")}</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;
  if (!product) return <div className="text-center text-gray-600">{t("productNotFound")}</div>;

  const {
    name: productName = t("noName"),
    mainimage,
    description = [],
    addinfo = [],
    addimage = [],
  } = product;

  const renderRichText = (richTextArray) => {
    if (!Array.isArray(richTextArray) || richTextArray.length === 0) {
      return <p className="text-gray-500">{t("noInformationAvailable")}</p>;
    }
  
    const renderChildren = (children) => {
      return children.map((child, childIndex) => {
        let content = child.text;
  
        if (child.bold) content = <strong key={childIndex}>{content}</strong>;
        if (child.italic) content = <em key={childIndex}>{content}</em>;
        if (child.underline) content = <u key={childIndex}>{content}</u>;
        if (child.strikethrough) content = <s key={childIndex}>{content}</s>;
  
        return content;
      });
    };
  
    return richTextArray.map((block, index) => {
      switch (block.type) {
        case "paragraph":
          return (
            <p key={index} className="mb-4 text-gray-700 text-justify leading-relaxed">
              {renderChildren(block.children)}
            </p>
          );
  
        case "bulleted-list":
        case "list": // Обработка общих списков
          return (
            <ul key={index} className="list-disc pl-5 mb-4 text-gray-700 text-justify">
              {block.children.map((listItem, idx) => (
                <li key={idx}>{renderChildren(listItem.children)}</li>
              ))}
            </ul>
          );
  
        case "numbered-list":
          return (
            <ol key={index} className="list-decimal pl-5 mb-4 text-gray-700 text-justify">
              {block.children.map((listItem, idx) => (
                <li key={idx}>{renderChildren(listItem.children)}</li>
              ))}
            </ol>
          );
  
        case "heading-one":
          return (
            <h1 key={index} className="text-3xl font-bold mb-4 text-gray-800 leading-tight">
              {renderChildren(block.children)}
            </h1>
          );
  
        case "heading-two":
          return (
            <h2 key={index} className="text-2xl font-bold mb-4 text-gray-800 leading-tight">
              {renderChildren(block.children)}
            </h2>
          );
  
        case "heading-three":
          return (
            <h3 key={index} className="text-xl font-bold mb-4 text-gray-800 leading-tight">
              {renderChildren(block.children)}
            </h3>
          );
  
        case "heading-four":
          return (
            <h4 key={index} className="text-lg font-semibold mb-4 text-gray-800 leading-tight">
              {renderChildren(block.children)}
            </h4>
          );
  
        case "heading-five":
          return (
            <h5 key={index} className="text-base font-semibold mb-4 text-gray-800 leading-tight">
              {renderChildren(block.children)}
            </h5>
          );
  
        case "heading-six":
          return (
            <h6 key={index} className="text-sm font-semibold mb-4 text-gray-800 leading-tight">
              {renderChildren(block.children)}
            </h6>
          );
  
        default:
          console.warn(`Unsupported block type: ${block.type}`);
          return null;
      }
    });
  };
  
  

  const renderCarousel = () => {
    const images = [
      ...(mainimage
        ? [
            {
              url: mainimage?.formats?.large?.url || mainimage?.url,
              thumbnail: mainimage?.formats?.thumbnail?.url || mainimage?.url,
            },
          ]
        : []),
      ...(Array.isArray(addimage) && addimage.length > 0
        ? addimage.map((image) => ({
            url: image?.formats?.large?.url || image?.url,
            thumbnail: image?.formats?.thumbnail?.url || image?.url,
          }))
        : []),
    ];

    if (images.length === 0) {
      return (
        <p className="text-sm text-gray-500">{t("noAdditionalImages")}</p>
      );
    }

    return (
      <div className="flex space-x-2 mt-4">
        {images.map((img, index) => (
          <img
            key={index}
            src={`${API_URL}${img.thumbnail}`}
            alt={`${t("thumbnail")} ${index + 1}`}
            onClick={() => setCurrentImage(`${API_URL}${img.url}`)}
            className={`w-16 h-16 object-cover rounded-md cursor-pointer border ${
              `${API_URL}${img.url}` === currentImage
                ? "border-blue-500"
                : "border-gray-300"
            } hover:border-blue-500 transition`}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="max-w-5xl bg-white-50 mx-auto px-4 py-8">
      <Link
        to="/products"
        className="text-blue-600 hover:underline text-sm mb-4 inline-block"
      >
        {t("backToProducts")}
      </Link>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div>
          <img
            src={currentImage}
            alt={productName}
            className="w-full h-auto rounded-lg shadow-lg md:max-h-[500px] object-cover"
          />
          {renderCarousel()}
        </div>
        <div className="md:col-span-2">
          <h1 className="text-2xl font-semibold text-gray-800 mb-4">{productName}</h1>
          <div className="text-gray-600 text-justify leading-relaxed">
            {renderRichText(description)}
          </div>
        </div>
      </div>
      <div className="mt-8 bg-orange-50 p-6 rounded-lg shadow">
        <div className="text-gray-600 text-justify leading-relaxed">
          {renderRichText(addinfo)}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
