import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const API_URL = process.env.REACT_APP_API_URL || "http://api.drkang.kz";
  const [aboutUsData, setAboutUsData] = useState([]);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchAboutUsData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/aboutus-cosmeterias?populate=*&locale=${i18n.language}`
        );
        const data = response?.data?.data || [];

        if (data.length) {
          const orderValues = ["First", "Second", "Third", "Fourth", "Fifth"];
          const sortedData = data.sort((a, b) => {
            const orderA = orderValues.indexOf(a.order || "");
            const orderB = orderValues.indexOf(b.order || "");
            return orderA - orderB;
          });
          setAboutUsData(sortedData);
        } else {
          setError(t("no_data_available"));
        }
      } catch (error) {
        console.error(t("error_loading_about_us"), error);
        setError(t("error_loading_about_us"));
      }
    };

    fetchAboutUsData();
  }, [API_URL, i18n.language, t]);

  const renderRichText = (richTextArray) => {
    if (!Array.isArray(richTextArray) || richTextArray.length === 0) {
      return <p className="text-gray-500">{t("noInformationAvailable")}</p>;
    }
  
    const renderChildren = (children) => {
      return children.map((child, childIndex) => {
        let content = child.text;
  
        if (child.bold) content = <strong key={childIndex}>{content}</strong>;
        if (child.italic) content = <em key={childIndex}>{content}</em>;
        if (child.underline) content = <u key={childIndex}>{content}</u>;
        if (child.strikethrough) content = <s key={childIndex}>{content}</s>;
  
        return content;
      });
    };
  
    return richTextArray.map((block, index) => {
      switch (block.type) {
        case "paragraph":
          return (
            <p key={index} className="mb-4 text-gray-700 text-justify leading-relaxed">
              {renderChildren(block.children)}
            </p>
          );
  
        case "bulleted-list":
        case "list": // Обработка общих списков
          return (
            <ul key={index} className="list-disc pl-5 mb-4 text-gray-700 text-justify">
              {block.children.map((listItem, idx) => (
                <li key={idx}>{renderChildren(listItem.children)}</li>
              ))}
            </ul>
          );
  
        case "numbered-list":
          return (
            <ol key={index} className="list-decimal pl-5 mb-4 text-gray-700 text-justify">
              {block.children.map((listItem, idx) => (
                <li key={idx}>{renderChildren(listItem.children)}</li>
              ))}
            </ol>
          );
  
        case "heading-one":
          return (
            <h1 key={index} className="text-3xl font-bold mb-4 text-gray-800 leading-tight">
              {renderChildren(block.children)}
            </h1>
          );
  
        case "heading-two":
          return (
            <h2 key={index} className="text-2xl font-bold mb-4 text-gray-800 leading-tight">
              {renderChildren(block.children)}
            </h2>
          );
  
        case "heading-three":
          return (
            <h3 key={index} className="text-xl font-bold mb-4 text-gray-800 leading-tight">
              {renderChildren(block.children)}
            </h3>
          );
  
        case "heading-four":
          return (
            <h4 key={index} className="text-lg font-semibold mb-4 text-gray-800 leading-tight">
              {renderChildren(block.children)}
            </h4>
          );
  
        case "heading-five":
          return (
            <h5 key={index} className="text-base font-semibold mb-4 text-gray-800 leading-tight">
              {renderChildren(block.children)}
            </h5>
          );
  
        case "heading-six":
          return (
            <h6 key={index} className="text-sm font-semibold mb-4 text-gray-800 leading-tight">
              {renderChildren(block.children)}
            </h6>
          );
  
        default:
          console.warn(`Unsupported block type: ${block.type}`);
          return null;
      }
    });
  };
  
  

  if (error) {
    return <p className="text-center text-red-500 text-xl mt-4">{error}</p>;
  }

  if (!aboutUsData.length) {
    return (
      <p className="text-center text-gray-500 text-xl mt-4">
        {t("no_data_available")}
      </p>
    );
  }

  return (
    <div id="about" className="container mx-auto mt-10 px-4">
      {aboutUsData.map((item, index) => {
        const imageUrl = item.image?.formats?.large?.url
          ? `${API_URL}${item.image.formats.large.url}`
          : null;

        return (
          <div
            key={item.id}
            className={`flex flex-col md:flex-row ${
              index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
            } items-center bg-white-100 shadow-md rounded-lg p-8 mb-10`}
          >
            <div className="w-full md:w-1/2 flex justify-center mb-4 md:mb-0">
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt={`About Us ${item.order}`}
                  className="rounded-lg shadow-lg w-full max-w-md object-cover"
                />
              ) : (
                <div className="bg-gray-300 w-3/4 h-64 flex items-center justify-center rounded-lg">
                  <p className="text-gray-500">{t("no_image_available")}</p>
                </div>
              )}
            </div>
            <div className="w-full md:w-1/2 text-gray-700 md:px-6 text-xl text-justify">
              {renderRichText(item.text || [])}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AboutUs;
