import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";


const BestProducts = () => {
  const [bestProducts, setBestProducts] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL || "http://api.drkang.kz";
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchBestProducts = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/cosmeteria-products?populate=mainimage&locale=${i18n.language}`
        );
        console.log("Full API Response:", response.data); // Debugging response

        const products = response.data.data || [];
        // Фильтруем только те продукты, где bestproduct === true
        const filteredBestProducts = products.filter(
          (product) => product.bestproduct
        );

        setBestProducts(filteredBestProducts);
      } catch (error) {
        console.error(t("errorLoadingData"), error);
      }
    };

    fetchBestProducts();
  }, [API_URL, i18n.language, t]);

  if (!bestProducts.length) {
    return (
      <div className="flex items-center justify-center h-[500px]">
        <p className="text-center text-lg text-gray-500">
          {t("no_best_products")}
        </p>
      </div>
    );
  }

  return (
    <div className="container mx-auto my-12 px-4">
      <div className="text-center">
        <h2 className="text-4xl font text-black-100 mb-12">
          {t("best_products")}
        </h2>
      </div>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000 }}
        spaceBetween={30}
        slidesPerView={1}
        breakpoints={{
          640: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
        }}
        className="swiper-container"
      >
        {bestProducts.map((product) => {
          const { id, name, mainimage, info } = product;
          const imageUrl = mainimage?.formats?.medium?.url
            ? `${API_URL}${mainimage.formats.medium.url}`
            : null;

          return (
            <SwiperSlide key={id}>
              <Link
                to={`/products/${encodeURIComponent(name)}`}
                className="p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-transform duration-300 transform hover:scale-105"
              >
                <div className="relative w-full h-[400px] overflow-hidden rounded-lg">
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt={name || "Продукт"}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="w-full h-full bg-gray-100 flex items-center justify-center rounded-lg text-gray-500 text-sm">
                      {t("no_image")}
                    </div>
                  )}
                </div>
                <div className="mt-4 text-center">
                  <h3 className="text-lg font-semibold text-gray-900">
                    {name || t("no_name")}
                  </h3>
                  <p className="text-sm text-gray-600">
                    {info || t("no_info")}
                  </p>
                </div>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default BestProducts;
