import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AiOutlinePhone,
  AiOutlineHome,
  AiOutlineShopping,
  AiOutlineTeam,
  AiOutlinePicture,
  AiOutlineGlobal,
} from "react-icons/ai";
import axios from "axios";
import { scroller } from "react-scroll";
import { useTranslation } from "react-i18next";

const Header = () => {
  const [logoUrl, setLogoUrl] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showLogo, setShowLogo] = useState(true);
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const API_URL = process.env.REACT_APP_API_URL || "http://api.drkang.kz";

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/cosmeteria-identities?populate=mainicon`
        );
        const mainiconUrl = response?.data?.data?.[0]?.mainicon?.url;
        if (mainiconUrl) {
          setLogoUrl(`${API_URL}${mainiconUrl}`);
        } else {
          console.warn("URL логотипа отсутствует в поле mainicon.");
        }
      } catch (error) {
        console.error("Ошибка при загрузке логотипа:", error.message);
      }
    };

    fetchLogo();
  }, [API_URL]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsScrolled(scrollY > 50);
      setShowLogo(scrollY < 150);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToSection = (sectionId) => {
    scroller.scrollTo(sectionId, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setShowLanguageMenu(false);
    navigate("/");
  };

  return (
    <>
      <header
        className={`fixed top-0 w-full z-50 shadow-md transition-all duration-300 ${
          isScrolled ? "bg-white/90 backdrop-blur-md" : "bg-white/70 md:bg-white/10"
        }`}
      >
        {showLogo && (
          <div className="flex justify-center items-center py-6 transition-opacity duration-300">
            {logoUrl ? (
              <Link to="/">
                <img
                  src={logoUrl}
                  alt="Логотип"
                  className="h-14 cursor-pointer"
                />
              </Link>
            ) : (
              <span className="text-xl font-semibold">Логотип</span>
            )}
          </div>
        )}

        <nav className="hidden md:flex justify-center space-x-8 text-xs tracking-wider md:text-lg">
          <button
            onClick={() => scrollToSection("about")}
            className={`hover:text-blue-500 transition-colors ${
              location.pathname === "/" ? "text-gray-800" : "hidden"
            }`}
          >
            {t("about")}
          </button>
          <button
            onClick={() => scrollToSection("partners")}
            className={`hover:text-blue-500 transition-colors ${
              location.pathname === "/" ? "text-gray-800" : "hidden"
            }`}
          >
            {t("partners")}
          </button>
          <Link
            to="/products"
            className={`hover:text-blue-500 transition-colors ${
              location.pathname === "/products" ? "text-blue-500" : "text-gray-800"
            }`}
          >
            {t("products")}
          </Link>
          <Link
            to="/gallery"
            className={`hover:text-blue-500 transition-colors ${
              location.pathname === "/gallery" ? "text-blue-500" : "text-gray-800"
            }`}
          >
            {t("gallery")}
          </Link>
          <button
            onClick={() => scrollToSection("footer")}
            className="hover:text-blue-500 transition-colors text-gray-800"
          >
            {t("contacts")}
          </button>
        </nav>
      </header>

      <div className="fixed bottom-0 left-0 w-full bg-white text-gray-800 z-50 shadow-lg md:hidden">
        <nav className="flex justify-around items-center py-3">
          <button
            onClick={() => scrollToSection("about")}
            className={`flex flex-col items-center text-sm hover:text-blue-500 transition-colors ${
              location.pathname === "/" ? "text-gray-800" : "hidden"
            }`}
          >
            <AiOutlineHome size={20} />
            <span className="text-xs">{t("about")}</span>
          </button>
          <button
            onClick={() => scrollToSection("partners")}
            className={`flex flex-col items-center text-sm hover:text-blue-500 transition-colors ${
              location.pathname === "/" ? "text-gray-800" : "hidden"
            }`}
          >
            <AiOutlineTeam size={20} />
            <span className="text-xs">{t("partners")}</span>
          </button>
          <Link
            to="/products"
            className="flex flex-col items-center text-sm hover:text-blue-500 transition-colors text-gray-800"
          >
            <AiOutlineShopping size={20} />
            <span className="text-xs">{t("products")}</span>
          </Link>
          <Link
            to="/gallery"
            className="flex flex-col items-center text-sm hover:text-blue-500 transition-colors text-gray-800"
          >
            <AiOutlinePicture size={20} />
            <span className="text-xs">{t("gallery")}</span>
          </Link>
          <button
            onClick={() => scrollToSection("footer")}
            className="flex flex-col items-center text-sm hover:text-blue-500 transition-colors text-gray-800"
          >
            <AiOutlinePhone size={20} />
            <span className="text-xs">{t("contacts")}</span>
          </button>
        </nav>
      </div>

      {/* Кнопка выбора языка для мобильных */}
      <div className="fixed bottom-16 right-4 z-50">
  <button
    onClick={() => setShowLanguageMenu(!showLanguageMenu)}
    className="flex items-center space-x-2 bg-white text-black px-4 py-2 rounded-full shadow-lg hover:bg-blue-400 transition"
  >
    <img
      src={`/flags/${i18n.language}.png`}
      alt={`${i18n.language} flag`}
      className="w-6 h-6 rounded-full"
    />
    <span>{i18n.language.toUpperCase()}</span>
  </button>
  {showLanguageMenu && (
    <div className="absolute bottom-10 right-0 bg-white shadow-lg rounded-md text-sm">
      <button
        onClick={() => changeLanguage("ru")}
        className="flex items-center px-4 py-2 hover:bg-gray-100"
      >
        <img
          src="/flags/ru.png"
          alt="Russian flag"
          className="w-6 h-6 rounded-full mr-2"
        />
        RU
      </button>
      <button
        onClick={() => changeLanguage("en")}
        className="flex items-center px-4 py-2 hover:bg-gray-100"
      >
        <img
          src="/flags/en.png"
          alt="English flag"
          className="w-6 h-6 rounded-full mr-2"
        />
        EN
      </button>
      <button
        onClick={() => changeLanguage("kk")}
        className="flex items-center px-4 py-2 hover:bg-gray-100"
      >
        <img
          src="/flags/kk.png"
          alt="Kazakh flag"
          className="w-6 h-6 rounded-full mr-2"
        />
        KZ
      </button>
    </div>
  )}
</div>
    </>
  );
};

export default Header;
