import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [logoUrl, setLogoUrl] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL || "http://api.drkang.kz";
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/cosmeteria-identities?populate=mainicon`
        );
        const mainiconUrl = response?.data?.data?.[0]?.mainicon?.url;
        if (mainiconUrl) {
          setLogoUrl(`${API_URL}${mainiconUrl}`);
        } else {
          console.warn("URL логотипа отсутствует в поле mainicon.");
        }
      } catch (error) {
        console.error("Ошибка при загрузке логотипа:", error.message);
      }
    };

    fetchLogo();
  }, [API_URL]);

  return (
    <footer id="footer" className="bg-gray-100 text-gray-800 py-8 px-4 mt-10 md:mt-16">
      <div className="container mx-auto flex flex-col items-center space-y-4">
        {/* Логотип */}
        {logoUrl && (
          <Link to="/">
            <img
              src={logoUrl}
              alt="Logo"
              className="h-12 cursor-pointer hover:opacity-80 transition-opacity"
            />
          </Link>
        )}
        {/* Instagram */}
        <div className="flex items-center space-x-2">
          <FaInstagram className="text-2xl text-gray-800 hover:text-pink-500 transition-colors" />
          <a
            href="https://www.instagram.com/cosmeteria.kz/?locale=ru&hl=am-et"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-800 hover:text-pink-500 text-sm transition-colors"
          >
            @cosmeteria.kz
          </a>
        </div>
        {/* Копирайт */}
        <p className="text-xs text-gray-500 mt-4">
          {new Date().getFullYear()} Cosmeteria. {t("rights")}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
