import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import Gallery from "./pages/Gallery";
import Products from "./pages/Products";
import ProductDetails from "./components/ProductDetails";
import Partners from "./components/Partners";
import BestProducts from "./components/BestProducts"
import AboutUs from "./components/AboutUs"
import Footer from "./components/Footer"
import './App.css';

const App = () => {
  const location = useLocation();

  const isHomePage = location.pathname === "/";

  return (
    <>
      <div id="root">
      <Header />
      <div className="main-content">
        <div className={`${isHomePage ? "" : "pt-[120px]"}`}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/:name" element={<ProductDetails />} />
          </Routes>
          {isHomePage && <AboutUs />}
          {isHomePage && <BestProducts />}
          {isHomePage && <Partners />}
        </div>
      </div>
      <Footer />
    </div>
      
    </>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;

