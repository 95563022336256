import React, { useEffect, useState } from "react";
import axios from "axios";

const Home = () => {
  const [backgroundImageUrl, setBackgroundImageUrl] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL || "http://api.drkang.kz";

  useEffect(() => {
    const fetchBackgroundImage = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/cosmeteria-identities?populate=homeimage`
        );
        const homeImageUrl = response?.data?.data?.[0]?.homeimage?.url;

        if (homeImageUrl) {
          setBackgroundImageUrl(`${API_URL}${homeImageUrl}`);
        } else {
          console.warn("Поле homeimage отсутствует в API.");
        }
      } catch (error) {
        console.error("Ошибка при загрузке изображения:", error.message);
      }
    };

    fetchBackgroundImage();
  }, [API_URL]);

  return (
    <div className="flex flex-col items-center">
      {backgroundImageUrl ? (
        <div className="w-full">
          {/* Отступ только на мобильных устройствах */}
          <div className="block sm:hidden mt-[112px]"></div>
          <img
            src={backgroundImageUrl}
            alt="Home Background"
            className="w-full h-auto object-cover"
          />
        </div>
      ) : (
        <p className="text-gray-700">Загрузка изображения...</p>
      )}
    </div>
  );
};

export default Home;
