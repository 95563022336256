import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const itemsPerPage = 10;
  const API_URL = process.env.REACT_APP_API_URL || "http://api.drkang.kz";
  const { t, i18n } = useTranslation();

  // Fetching products data
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/cosmeteria-products?populate=mainimage&locale=${i18n.language}`
        );
        console.log("API Response:", response.data); // Debug

        const productsData = response.data.data || [];
        setProducts(productsData);

        const uniqueClasses = [
          ...new Set(productsData.map((product) => product.class).filter(Boolean)),
        ];
        setClasses(uniqueClasses);
        setFilteredProducts(productsData);
      } catch (error) {
        console.error(t("errorLoadingData"), error);
      }
    };

    fetchProducts();
  }, [API_URL, i18n.language, t]);

  const handleClassClick = (className) => {
    setSelectedClass(className);

    if (className === "") {
      setFilteredProducts(products);
    } else {
      setFilteredProducts(
        products.filter((product) => product.class === className)
      );
    }

    setCurrentPage(0);
  };

  const getLargestImageUrl = (formats) => {
    if (!formats) return null; // Если форматы отсутствуют
    if (formats.large) return `${API_URL}${formats.large.url}`;
    if (formats.medium) return `${API_URL}${formats.medium.url}`;
    if (formats.small) return `${API_URL}${formats.small.url}`;
    if (formats.thumbnail) return `${API_URL}${formats.thumbnail.url}`;
    return null;
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = filteredProducts.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(filteredProducts.length / itemsPerPage);

  const handlePageClick = ({ selected }) => setCurrentPage(selected);

  return (
    <div className="container mx-auto mt-5">
      {/* Filter Text */}
      <div className="flex justify-center gap-6 mb-8 text-lg">
        <span
          className={`cursor-pointer ${
            selectedClass === "" ? "text-black " : "text-gray-400 hover:text-black"
          }`}
          onClick={() => handleClassClick("")}
        >
          {t("all")}
        </span>
        {classes.map((className, index) => (
          <span
            key={index}
            className={`cursor-pointer ${
              selectedClass === className ? "text-black" : "text-gray-400 hover:text-black"
            }`}
            onClick={() => handleClassClick(className)}
          >
            {className}
          </span>
        ))}
      </div>

      {/* Products Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {currentItems.length > 0 ? (
          currentItems.map((product, index) => {
            console.log(`Product ${index + 1}:`, product); // Debug
            const name = product.name || t("no_name");
            const info = product.info || t("no_info");
            const imageUrl = product.mainimage?.formats
              ? getLargestImageUrl(product.mainimage.formats)
              : null;

            return (
              <Link
                to={`/products/${encodeURIComponent(name)}`}
                key={product.id}
                className="flex flex-col items-center p-6 bg-orange-50 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
              >
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt={name}
                    className="w-full h-80 object-contain mb-4"
                  />
                ) : (
                  <div className="w-full h-60 bg-gray-200 flex items-center justify-center rounded-md mb-4">
                    {t("no_image")}
                  </div>
                )}
                <h2 className="text-lg font-semibold text-center text-gray-800 mb-2">{name}</h2>
                <p className="text-gray-600 text-sm text-center">{info}</p>
              </Link>
            );
          })
        ) : (
          <p className="text-center text-gray-600">{t("no_products_available")}</p>
        )}
      </div>

      {/* Pagination */}
      <ReactPaginate
        previousLabel={"←"}
        nextLabel={"→"}
        breakLabel={"..."}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={"flex justify-center mt-5 space-x-2 items-center"}
        pageClassName={
          "px-4 py-2 text-gray-600 rounded-md hover:bg-gray-100 hover:text-black transition"
        }
        activeClassName={"bg-gray-900 text-white font-bold"}
        previousClassName={
          "px-4 py-2 text-gray-600 rounded-md hover:bg-gray-100 hover:text-black transition"
        }
        nextClassName={
          "px-4 py-2 text-gray-600 rounded-md hover:bg-gray-100 hover:text-black transition"
        }
        breakClassName={"px-4 py-2 text-gray-400"}
        disabledClassName={"opacity-50 cursor-not-allowed"}
      />
    </div>
  );
};

export default Products;
