import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Partners = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const { t } = useTranslation();
  const [partnersData, setPartnersData] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/api/partners?populate=*`)
      .then((response) => {
        const partners = response.data.data.map((partner) => {
          const imageUrl = partner.partnerimage?.url
            ? `${API_URL}${partner.partnerimage.url}`
            : null;
          const linkUrl = partner.link || "#";
          return { ...partner, imageUrl, linkUrl };
        });
        setPartnersData(partners);
      })
      .catch((error) => {
        console.error("Ошибка при получении данных партнёров:", error);
      });
  }, []);

  return (
    <div id="partners" className="container mx-auto py-8 px-4">
      <h2 className="text-3xl font text-center mb-6">{t("partnersTitle")}</h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6">
        {partnersData.length > 0 ? (
          partnersData.map((partner, index) => (
            <div
              key={partner.id}
              className="flex flex-col items-center justify-center space-y-2"
            >
              {partner.imageUrl ? (
                <a
                  href={partner.linkUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block"
                >
                  <img
                    src={partner.imageUrl}
                    alt={`Partner ${index + 1}`}
                    className="w-40 h-20 object-contain transition-transform hover:scale-105"
                  />
                </a>
              ) : (
                <div className="w-40 h-20 bg-gray-100 flex items-center justify-center text-gray-500 text-sm">
                  No image available
                </div>
              )}
            </div>
          ))
        ) : (
          <p className="text-center text-gray-600 col-span-full">
            Изображения партнёров отсутствуют или не загружаются.
          </p>
        )}
      </div>
    </div>
  );
};

export default Partners;
