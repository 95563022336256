import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Gallery = () => {
  const [galleryItems, setGalleryItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL || "http://api.drkang.kz";
  const { t } = useTranslation(); // Подключение локализации

  // Fetch gallery data
  useEffect(() => {
    const fetchGallery = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/cosmeteria-identities?populate=gallery`
        );
        const galleryData = response?.data?.data?.[0]?.gallery;

        if (galleryData) {
          const items = galleryData.map((media) => {
            const isVideo = media.mime.startsWith("video");
            const url = `${API_URL}${media.url}`;
            const thumbnail = isVideo
              ? `${API_URL}${media.formats?.thumbnail?.url || media.url}`
              : `${API_URL}${media.formats?.medium?.url || media.formats?.thumbnail?.url || media.url}`;

            return {
              type: isVideo ? "video" : "image",
              url,
              thumbnail,
            };
          });

          setGalleryItems(items);
        } else {
          console.warn(t("noGalleryItems"));
        }
      } catch (error) {
        console.error(t("errorLoadingData"), error.message);
      }
    };

    fetchGallery();
  }, [API_URL, t]);

  // Open the modal
  const openModal = (item) => {
    setModalItem(item);
    setIsModalOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setModalItem(null);
    setIsModalOpen(false);
  };

  // Render gallery items
  const renderGallery = useCallback(() => {
    return galleryItems.map((item, index) => (
      <div
        key={index}
        className="relative group rounded-lg overflow-hidden shadow-md cursor-pointer transform hover:scale-105 transition-transform duration-300"
        onClick={() => openModal(item)}
      >
        {item.type === "image" ? (
          <img
            src={item.thumbnail}
            alt={`${t("galleryImage")} ${index + 1}`}
            className="w-full h-[300px] object-cover"
            loading="lazy"
          />
        ) : (
          <video
            src={item.url}
            className="w-full h-[300px] object-cover"
            muted
            autoPlay
            loop
          />
        )}
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <p className="text-white font-semibold">{t("open")}</p>
        </div>
      </div>
    ));
  }, [galleryItems, t]);

  return (
    <div className="container mx-auto py-12 px-4">
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6">
        {galleryItems.length > 0 ? (
          renderGallery()
        ) : (
          <p className="text-center col-span-full text-lg text-gray-500">
            {t("noGalleryItems")}
          </p>
        )}
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div
          className="fixed inset-0 w-full h-full bg-black bg-opacity-90 flex justify-center items-center z-50"
          onClick={closeModal}
        >
          <button
            onClick={closeModal}
            className="absolute top-4 right-4 text-white bg-red-500 hover:bg-red-600 rounded-full p-3 z-10"
          >
            &times;
          </button>
          {modalItem.type === "image" ? (
            <img
              src={modalItem.url}
              alt={t("galleryImagePreview")}
              className="w-auto max-h-full object-contain"
            />
          ) : (
            <video
              src={modalItem.url}
              className="w-auto max-h-full object-contain"
              controls
              autoPlay
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Gallery;
